import './App.css';
import { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext"
import Login from './pages/login/Login';
import { superappUIUrl } from './utils/authRequest';
import { ACTION_TYPES } from './utils/definitions';

function App() {
  const { user, dispatch } = useContext(AuthContext);
  //clear localStorage
  if (user) {
    dispatch({type: ACTION_TYPES.LOGOUT})
    window.localStorage.clear()
    window.localStorage.removeItem('user')
  }
  return (
    <div className="appContainer">
      {
        user ? 
        ''
        :
        <Routes>
          <Route path="/redirect/:source" element={<Login />} />
          <Route path="/" element={<Navigate to='/redirect/base' />} />
        </Routes>
      }
    </div>
  );
}

export default App;
