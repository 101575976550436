import './login.css'
import { params, useParams } from 'react-router-dom'
import carelonLogoSvg from "../../resources/carelon/carelon-logo-colored.svg"
import MultiStepForm from '../../components/multiStepForm/MultiStepForm'

const Login = () => {
  const { source } = useParams()
  return (
    <div className="login">
        <div className="upperSection">
            <div className="loginTitles">
                <img src={carelonLogoSvg} alt="" className="loginCompanyLogo"/>
                <span className="appName">CGS SuperApp</span>
            </div>
        </div>
        <div className="lowerSection">
            <MultiStepForm source={source} />
        </div>
    </div>
  )
}

export default Login