import { serverMode } from "./config";

//Workplace Auth
export const authLocalhost = 'http://localhost:4004/auth';
export const authProduction = 'https://workplace.teamcgs.io/auth'
//Workplace UI
export const workplaceUIProduction = 'https://workplace.teamcgs.io'
export const workplaceUILocalhost = 'http://localhost:3000'
//SuperApp UI
export const superappUIProduction = 'https://teamcgs.io'
export const superappUILocalhost = 'http://localhost:3000'
//CGS Auth API
export const cgsauthAPIProduction = 'https://auth.teamcgs.io/auth'
export const cgsauthAPILocalhost = 'http://localhost:4004/auth'
//CGS Auth UI
export const cgsauthUIProduction = 'https://auth.teamcgs.io/'
export const cgsauthUILocalhost = 'http://localhost:3005'
//CGS Training UI
export const cgsTrainingUIProduction = 'https://training.teamcgs.io'
export const cgsTrainingUILocalhost = 'http://localhost:3006'

export const workplaceUIUrl = serverMode ? workplaceUIProduction : workplaceUILocalhost 
export const superappUIUrl = serverMode ? superappUIProduction : superappUILocalhost
export const trainingUIUrl = serverMode ? cgsTrainingUIProduction : cgsTrainingUILocalhost

export const ACTION_TYPES = {
    START: "START",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOGOUT: "LOGOUT",
};

export const SPACE_TYPES_NAMES = {
    CONFERENCE: "Conference Rooms",
    TRAINING: "Training Rooms",
    GYM: "Gyms",
    SLEEPING_QUARTER: "Sleeping Quarters",
    SEAT: "Seats"
}