import './domainForm.css'
import FormWrapper from '../formWrapper/FormWrapper'
import TextField from '@mui/material/TextField';

const DomainForm = ({handleNext, clearError, title, validateDomainId}) => {
  const handleChange = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleNext(e)
      }
      clearError()
      let input = e.target.value
      input = input.toUpperCase()
      validateDomainId(input)
  }

  const style = {
    "fieldset": {
      borderColor: "#5009B5"
    },
    "& label.Mui-focused": {
      color: "#5009B5"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5009B5"
      }
    }
  }  
  return (
    <FormWrapper title={title}>
      <TextField 
        style={{width: 300}} 
        sx={style} 
        inputProps={{maxLength: 7}} 
        fullWidth 
        id="multiForm-domainId" 
        className='multiForm-domainId' 
        label="Domain ID" 
        variant="outlined" 
        onKeyUp={handleChange}
        onKeyDown={handleChange}
        onChange={handleChange} 
        autoFocus 
        type='text'
      />
    </FormWrapper>
  )
}

export default DomainForm