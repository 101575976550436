import axios from "axios"
import { serverMode } from "./config";
import { cgsauthAPILocalhost, cgsauthAPIProduction } from "./definitions";

export const cgsauthAPIRequestUrl = axios.create({
    baseURL: (serverMode ? cgsauthAPIProduction : cgsauthAPILocalhost),
    headers: {
        'Content-Type': 'application/json',
    },
})

