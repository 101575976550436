import './passwordForm.css'
import { useRef, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import FormWrapper from '../formWrapper/FormWrapper'
import TextField from '@mui/material/TextField';
import { numbersOnlyInput, passwordValidator } from '../../utils/helper';

const PasswordForm = ({domainAuthResponse, setFormActionText, formAction, validateRegisterForm, setCredentials, clearError, setDomainErrorMessage, setDomainErrorStatus, setError, handleNext}) => {
  const {isWithCreds, domainId} = domainAuthResponse

  return (
    <>
      { isWithCreds ? 
          <LoginForm 
            setFormActionText={setFormActionText} 
            setCredentials={setCredentials} 
            clearError={clearError}
            validateRegisterForm={validateRegisterForm}
            handleNext={handleNext}
          /> 
        : 
        <RegisterCredentials 
          setFormActionText={setFormActionText}
          validateRegisterForm={validateRegisterForm}
          setCredentials={setCredentials} 
          clearError={clearError}
          setError={setError}
          handleNext={handleNext}
        />
      }
    </>
  )
}
const RegisterCredentials = ({setFormActionText, validateRegisterForm, setCredentials, setDomainErrorStatus, setDomainErrorMessage, clearError, setError, handleNext}) => {
  setFormActionText('Set Credentials')
  const passwordInput = useRef()
  const confirmPasswordInput = useRef()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [ isPasswordHidden, setIsPasswordHidden ] = useState(true)

  const style = {
    "fieldset": {
      borderColor: "#5009B5"
    },
    "& label.Mui-focused": {
      color: "#5009B5"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5009B5"
      }
    }
  } 

  const togglePassword = e => {
    e.preventDefault();
    passwordInput.current.type = isPasswordHidden ? 'text' : 'password'
    confirmPasswordInput.current.type = isPasswordHidden ? 'text' : 'password'
    setIsPasswordHidden(!isPasswordHidden)
  }
  
  const handleChange = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleNext(e)
    }
    let input = e.target.value
    let id = e.target.id
    if(e.target.id === 'employeeId') {
      clearError()
      const lastCharacterTyped = numbersOnlyInput(input.substring(input.length-1, input.length))
      if(!lastCharacterTyped) {
        e.target.value = input.substring(0, input.length-1)
        setError('Numbers only for Employee ID', true)
      }
    }
    if(e.target.id === 'password') {
      setPassword(input)
      let isPasswordValid = passwordValidator(input)
      if(!isPasswordValid.includes('Password passed')) {
        setError(passwordValidator(input), true)
      }
      else {
        clearError()
      }
    }
    if(e.target.id === 'confirmPassword') {
      setConfirmPassword(input)
      if(password !== input) setError(['Passwords do not match'], true)
      else clearError()
    }
    validateRegisterForm(input, id)
  }
  return(
    <FormWrapper title='Set Your Credentials'>
      <TextField style={{width: 300}} sx={style} inputProps={{maxLength: 7}} fullWidth id="employeeId" className='multiForm-employeeId' label="Verify Last 7 Digits of Employee ID" variant="outlined" onPaste={handleChange} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} autoFocus type='text' />
      <div className="loginPasswordWrap">
        <TextField style={{width: 300}} sx={style} fullWidth id="password" className='multiForm-password' label="Set Password" variant="outlined" onPaste={e => e.preventDefault()} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} type='password' inputRef={passwordInput} />
        <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
      </div>
      <div className="loginPasswordWrap">
        <TextField style={{width: 300}} sx={style} fullWidth id="confirmPassword" className='multiForm-confirmPassword' label="Confirm Password" variant="outlined" onPaste={e => e.preventDefault()} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} type='password' inputRef={confirmPasswordInput}/>
        <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
      </div>
    </FormWrapper>
    )
}
const LoginForm = ({setFormActionText, setCredentials, clearError, validateRegisterForm, handleNext}) => {
  setFormActionText('Login Associate')
  const passwordInput = useRef()
  const [ isPasswordHidden, setIsPasswordHidden ] = useState(true)

  const togglePassword = e => {
    e.preventDefault();
    passwordInput.current.type = isPasswordHidden ? 'text' : 'password'
    setIsPasswordHidden(!isPasswordHidden)
  }

  const handleChange = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleNext(e)
    }
    clearError()
    let input = e.target.value
    let id = e.target.id
    validateRegisterForm(input, id)
  }
  return(
    <FormWrapper title='Enter Your Credentials'>
      <div className="loginPasswordWrap">
        <TextField style={{width: 300}} fullWidth id="password" className='multiForm-password' label="Enter Password" variant="outlined" onPaste={e => e.preventDefault()} onChange={handleChange} onKeyDown={handleChange} onKeyUp={handleChange} type='password' inputRef={passwordInput} />
        <FontAwesomeIcon icon={ isPasswordHidden === true ? faEyeSlash : faEye} className="loginEye" onClick={togglePassword}/>
      </div>
    </FormWrapper>
    )
}

export default PasswordForm